
.portion-calc {
    background-color: #FFFFFF;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    padding: 48px 20px 18px;
}

.portion-calc .operator {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
}

.portion-calc .left-calc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    border-radius: 12px;
    padding: 0 10px 0 10px;
    flex-grow: 1;
    flex-basis: 510px;
}

.portion-calc .right-calc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    border-radius: 12px;
    padding: 0 20px;
    flex-grow: 1;
    flex-basis: 280px;
}

.portion-calc .multiply {
    font-size: 14px;
    color: #313131;
    font-weight: 500;
}

/*.portion-calc .multiply > div:first-child {*/
/*    position: relative;*/
/*    top: -1px;*/
/*}*/

.portion-calc .equal {
    font-size: 14px;
    color: #313131;
    font-weight: 500;
}

/*.portion-calc .equal > div:first-child {*/
/*    position: relative;*/
/*    top: -1px;*/
/*}*/

.portion-calc .divide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    margin: 0 20px;
}

.portion-calc .divide > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #313131;
    color: #ffffff;
    font-weight: 500;
    font-size: 13px;
}

.portion-calc .yield {
    position: relative;
    flex-basis: 75px;
}

.portion-calc .portion-number {
    position: relative;
    flex-basis: 75px;
}

.portion-calc .portion-input {
    background-color: #EFEFEF;
    border-radius: 6px;
    height: 40px;
    width: 70px;
    border: none;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #313131;
}

.portion-calc .label {
    position: absolute;
    top: -32px;
    font-weight: 400;
    font-size: 12px;
    white-space: nowrap;
}

.portion-calc .mass {
    display: flex;
    align-items: end;
    justify-content: center;
    position: relative;
}

.portion-calc .mass .value {
    font-style: normal;
    font-weight: 500;
    border-radius: 6px;
    height: 40px;
    color: #9A9A9A;
    background-color: #DBDBDB;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
}

.portion-calc .mass .unit {
    font-weight: 500;
    font-size: 14px;
    color: #c3c2bf;
}

.portion-calc .net-amount {
    flex-basis: 180px;
    justify-content: start;
}

.portion-calc .prepared-amount {
    flex-basis: 180px;
}

.portion-calc .portion-amount {
    flex-basis: 160px;
}

