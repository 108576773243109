
.delete-modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    background-color: rgba(0, 0, 0, 0.22);
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-modal .box {
    width: 440px;
    border-radius: 8px;
    background-color: #FFFFFF;
    position: relative;
    padding: 28px 32px 80px 32px;
}

.delete-modal .header {
    margin-bottom: 15px;
}

.delete-modal .title {
    color: #272722;
    font-size: 20px;
    font-family: Inter;
    font-weight: 500;
    margin-bottom: 10px;
}

.delete-modal .message {
    color: #8C8A81;
    font-size: 16px;
    font-family: Inter;
    font-weight: 400;
    margin-bottom: 45px;
}

.delete-modal .footer {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: end;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 2px solid #ECE9E1;
    width: 100%;
}
