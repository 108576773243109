
.recipes-container {
    flex-grow: 2;
    margin-left: 30px;
    flex-basis: 910px;
}

.search-container {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin-left: 30px;
    flex-basis: 910px;
    margin-bottom: 20px;
    overflow: hidden;
}

.nutrical-request-container {
    flex-grow: 1;
    flex-basis: 300px;
    flex-shrink: 0;
    overflow: auto;
}


.nutrical-request-container .title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 13px;
    padding-left: 25px;
}


.recipe-empty-section {
    background-color: #EFEFEF;
    display: flex;
    padding: 30px;
    justify-content: center;
    align-items: center;
    color: #1F1F1F;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
    height: 120px;
}

.recipe-empty-section > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.recipe-empty-section img {
    width: 30px;
    margin-bottom: 10px;
}

.recipe-section-title {
    font-weight: 500;
    font-size: 18px;
    color: #505050;
    margin-bottom: 15px;
}

