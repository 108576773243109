.menu-item {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px 14px;
    color: #202020;
    font-size: 12px;

}

.menu-item:hover {
    cursor: pointer;
    background-color: #f1f1f1;
}