
.nutrical-request-table {
    width: 100%;
}

.nutrical-request-table table {
    width: 100%;
    text-align: left;
    table-layout: fixed;
    background-color: white;
    border-radius: 12px;
}

.nutrical-request-table thead tr {
    height: 52px;
}

.nutrical-request-table td {
    padding: 0 15px;
}

.nutrical-request-table th {
    padding: 0 15px;
}

/*.nutrical-request-table tbody tr:first-child td:first-child {*/
/*    border-top-left-radius: 12px;*/
/*}*/

/*.nutrical-request-table tbody tr:first-child td:last-child {*/
/*    border-top-right-radius: 12px;*/
/*}*/

/*.nutrical-request-table tr:last-child td:first-child {*/
/*    border-bottom-left-radius: 12px;*/
/*}*/

/*.nutrical-request-table tr:last-child td:last-child {*/
/*    border-bottom-right-radius: 12px;*/
/*}*/

.nutrical-request-table th {
    font-weight: 400;
    color: #313131;
    font-size: 12px;
    height: 42px;
}

.nutrical-request-table table tr {
    border-bottom: 1px solid #EFEFEF;
}

.nutrical-request-table tbody tr {
    height: 60px;
    font-size: 12px;
}

.nutrical-request-table tbody tr:hover {
    background-color: #EFEFEF;
}

.nutrical-request-table .align-center > *:first-child {
    margin: auto;
}

.nutrical-request-table .actions {
    height: 60px;
    text-align: right;
    padding-right: 10px;
}

.nutrical-request-table .actions button {
    display: inline-block;
}
