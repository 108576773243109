
.ingredient-declaration-table .fs-small {
    font-size: 12px;
}

.ingredient-declaration-table .text-area-field {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 0;
    right: 0;
}

.ingredient-declaration-table .ingredient-name {
    font-family: Inter;
    font-weight: 600;
    font-size: 14px;
    color: #1F1F1F;
}


.ingredient-declaration-table th:last-child {
    padding-right: 20px;
}

.ingredient-declaration-table tr td:last-child {
    padding-right: 20px;
}

