
.text-area-field {
    /*display: flex;*/
    /*flex-direction: column;*/
}

.empty-text-area-field textarea {
    background-color: #EFEFEF !important;
    border: none !important;
}

.text-area-field label {
    font-weight: 400;
    font-size: 14px;
    color: #313131;
    margin-bottom: 8px;
}

.text-area-field textarea {
    background-color: transparent;
    border: 1px solid #C1BFB0;
    border-radius: 8px;
    padding: 15px 15px;
    resize: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.text-area-field::placeholder {
    font-size: 14px;
    color: rgba(49, 49, 49, 0.6);
}