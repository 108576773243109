@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  .bg-pearl-white {
    background-color: #ECE9E1;
  }

  .bg-aqua {
    background-color: #B2F0E4;
  }

  .bg-black {
    background-color: #121212;
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EFEFEF;
  font-family: "Poppins";
}

body:has(.Modal) {
  overflow: hidden;
}

body:has(.delete-modal) {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* BACKGROUND */

.bg-ecru-white {
  background-color: #FCF2DD;
}


.text-gray-1 {
  color: #505050;
}


.hide-overflow {
  overflow: hidden;
}


.text-silver-chalice {
  color: #A8A8A8;
}



/* POSITION */
.relative {
  position: relative;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.center {
  justify-content: center;
}

.end {
  justify-content: end;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.align-start {
  align-items: start;
}

.flex-grow {
  flex-grow: 1;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-50 {
  margin-top: 50px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-50 {
  margin-right: 50px;
}


.pl-8 {
  padding-left: 8px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-18 {
  padding-left: 18px;
}

.pl-35 {
  padding-left: 35px;
}


.pr-50 {
  padding-right: 50px;
}

/* WIDTH */
.w-270 {
  width: 270px;
}

.w-400 {
  width: 400px;
}

.w-500 {
  width: 500px;
}

.w-100-pct {
  width: 100%;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

@font-face {
  font-family: "JockeyOne";
  src: local("JockeyOne"),
  url("./fonts/JockeyOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local("Inter"),
  url("./fonts/Inter-Regular.ttf") format("truetype");
}



/* SCREEN */
/*md (for small laptops - screens equal to or greater than 992px wide)*/
/*lg (for laptops and desktops - screens equal to or greater than 1200px wide)*/
/*xl (for laptops and desktops - screens equal to or greater than 1600px wide)*/
@media only screen and (min-width: 0px) {

  .w-100-pct-sm {
    width: 100%;
  }

  .w-80-pct-sm {
    width: 80%;
  }

  .w-60-pct-sm {
    width: 60%;
  }

  .w-40-pct-sm {
    width: 40%;
  }

  .w-20-pct-sm {
    width: 20%;
  }

  .w-10-pct-sm {
    width: 10%;
  }

}

/** MODAL **/

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: #82828282;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
}




/* TABLE LOADER */

.loader-line {
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #ddd;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: #FDAE37;;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}


.form-field-label {
  font-family: Inter;
  font-weight: 500;
  font-size: 10px;
  color: #121212;
  margin-bottom: 4px;
}


@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}





@media only screen and (min-width: 992px) {

  .w-100-pct-md {
    width: 100%;
  }

  .w-80-pct-md {
    width: 80%;
  }

  .w-60-pct-md {
    width: 60%;
  }

  .w-50-pct-md {
    width: 50%;
  }

  .w-40-pct-md {
    width: 40%;
  }

  .w-30-pct-md {
    width: 30%;
  }

  .w-25-pct-md {
    width: 25%;
  }

  .w-20-pct-md {
    width: 20%;
  }

  .w-10-pct-md {
    width: 10%;
  }

}

@media only screen and (min-width: 1200px) {

  .w-100-pct-lg {
    width: 100%;
  }

  .w-80-pct-lg {
    width: 80%;
  }

  .w-60-pct-lg {
    width: 60%;
  }

  .w-40-pct-lg {
    width: 40%;
  }

  .w-20-pct-lg {
    width: 20%;
  }

}

@media only screen and (min-width: 1600px) {

  .w-100-pct-xl {
    width: 100%;
  }

  .w-80-pct-xl {
    width: 80%;
  }

  .w-60-pct-xl {
    width: 60%;
  }

  .w-40-pct-xl {
    width: 40%;
  }

  .w-20-pct-xl {
    width: 20%;
  }

}

.dashed-border {
  background-image: linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;
}


.scroll-content {
  padding-right: 4px;
}

.scroll-content::-webkit-scrollbar {
  width:4px; /* Set the width of the custom scrollbar */
}

.scroll-content::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Border radius of the scrollbar thumb */
}

.scroll-content:hover::-webkit-scrollbar-thumb {
  background-color: #969696; /* Color of the scrollbar thumb */
}

.scroll-content::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the scrollbar track */
}

.scroll-content:hover::-webkit-scrollbar-track {
  background-color: #CBCBCB;
}


.hide-clear-icon {

}

.hide-clear-btn::-webkit-search-cancel-button,
.hide-clear-btn::-webkit-search-decoration,
.hide-clear-btn::-webkit-search-results-button {
  display: none; /* Hide the default x icon */
}


/**
    Density modal
 */
.category:not(:has(.density)) {
  display: none;
}

/**
  Dropzone

 */

.dropzone {
  border: 2px dashed #D6D5D1;
  border-radius: 6px;
  padding: 32px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #313131;
}

.dropzone:hover {
  cursor: pointer;
  border: 2px dashed #b9b9b9;
}

.dropzone .upload-img {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E0DED4;
  border: solid 8px #e0ded44d;
}


.dropdown::-webkit-scrollbar {
  width: 6px; /* Set your desired width */
  border-radius: 20px;
}

.dropdown::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Set the border radius of the scrollbar thumb */
  transition: background-color 0.3s ease; /* Add a subtle transition effect */
}

.dropdown::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Set* Set the color of the scrollbar thumb on hover */
}

/* For Firefox */
.dropdown {
  scrollbar-width: thin;
  scrollbar-color: #ccc #fff;/* Set the color of the scrollbar thumb and track */
}

.dropdown:hover {
  scrollbar-color: #aaa #fff; /* Set the color of the scrollbar thumb and track on hover */
}