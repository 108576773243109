
.menu {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.menu.right .menu-items {
    left: unset;
    right: 0;
}

.menu-items {
    background-color: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 14px 20px rgba(31, 31, 31, 0.15);
    overflow: hidden;

    position: absolute;
    left: 10px;
    top: 100%;
    z-index: 2;
}

.menu #action-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background-color: #1F1F1F0D;
    border-radius: 8px;
    margin-left: 10px;
}

.menu #action-btn:hover {
    cursor: pointer;
    background-color: rgba(47, 47, 47, 0.05);
}

.menu-items .btn-start-icon {
    width: max-content;
}


