
.report-builder-container {

}

.report-builder-container .report-content .left {
    flex-grow: 2;
    flex-basis: 500px;
}

.report-builder-container .report-content .right {
    flex-grow: 1;
    flex-basis: 500px;
    margin-left: 20px;
}

.additional-fields {
    padding: 20px;
    background-color: #FFFFFF;
    border-radius: 12px;
}

.report-builder-container .title {
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: #1F1F1F;
    margin-bottom: 20px;
}



