
.navigation-wrapper {
    height: 60px;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
}

.navigation {
    background-color: #EFEFEF;
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    z-index: 99;
}



.navigation > div {
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background: #FFFFFF;
    box-shadow: 1px 9px 14px #E1E0D9, 0 2px 3px rgba(211, 210, 206, 0.96);
    padding: 0 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.navigation .logo {
    display: flex;
    align-items: center;
    flex-basis: 300px;
}

.navigation .logo span {
    font-family: JockeyOne;
    font-weight: 400;
    font-size: 24px;
    color: #393546;
    margin-left: 10px;
}

.navigation .items {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigation .user {
    flex-basis: 300px;
    display: flex;
    align-items: center;
    justify-content: right;
}

.navigation .item {

}

.navigation .item a {
    font-family: Inter;
    font-weight: 500;
    font-size: 12px;
    color: #1f1f1f99;
    margin: 0 20px;
    text-decoration: none;
    padding: 6px 9px 7px 9px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation .item a:hover:not(.selected) {
    background-color: #EEEDE8;
    cursor: pointer;
}

.navigation .item .selected {
    color: #303030;
    font-weight: 700;
}

.navigation .item a img {
    margin-right: 6px;
}

.navigation .profile-pic {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3C93AE;
    text-transform: uppercase;
    font-weight: 600;
    font-family: Inter;
    color: white;
}

.navigation .profile-pic:hover {
    cursor: pointer;
}

.navigation .profile-pic img {
    width: 36px;
}




