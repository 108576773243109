
.content-editable {
    width: 100%;
    height: 100%;
    border: none;
    position: relative;
    font-weight: 400;
}

.content-editable .text-area {
    width: 100%;
    height: 100%;
    background-color: white;
    box-sizing: border-box;
    outline: none;
    overflow: auto;
    border-radius: 6px;
    padding: 5px;
    border: 1px solid #C1BFB0;
}

.content-editable .text-area-empty {
   background-color: #EFEFEF;
   border: 1px solid transparent;
}

.content-editable #command {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-editable #command button:hover {
    cursor: pointer;
    background-color: rgb(243, 239, 237);
}

.content-editable #command button {
    width: 30px;
    height: 30px;
    font-weight: 800;
    background-color: rgb(236, 233, 225);
    border-radius: 4px;
    border: 3px solid white;
}