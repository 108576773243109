
.number-field {

}

.empty-number-field input {
    background-color: #EFEFEF;
    border: none;
}

.number-field label {
    font-weight: 500;
    font-size: 10px;
    color: #121212;
    margin-bottom: 8px;
}

.number-field input {
    /*background-color: #940404;*/
    /*border: 1px solid #C1BFB0;*/
    /*border-radius: 8px;*/
    /*height: 40px;*/
    /*padding: 0 15px;*/
    /*width: 100%;*/
    /*box-sizing: border-box;*/

    background-color: #EFEFEF;
    border-radius: 6px;
    height: 42px;
    padding: 0 15px;
    font-size: 14px;
}

.number-field .text-right {
    position: relative;
    left: -17px;
    font-size: 10px;
    color: #8C8A81;
}

.number-field-rounded input {
    border-radius: 20px !important;
}