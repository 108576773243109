
.text-field {
    display: flex;
    flex-direction: column;
    position: relative;
}

.text-field label {
    font-weight: 400;
    font-size: 14px;
    color: #313131;
    opacity: 0.7;
    margin-bottom: 4px;
}

.text-field input {
    border-radius: 6px;
    height: 42px;
    font-size: 14px;
}

.text-field .mandatory {
    color: #FDAE37;
}

.text-field .error {
    position: absolute;
    bottom: -12px;
    font-size: 9px;
    color: #c30000;
}