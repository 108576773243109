
.ingredient-table {

}

.ingredient-table > table {
    table-layout: fixed;
    width: 100%;
    text-align: left;
    background-color: #FFFFFF;
    border-collapse: collapse;
}

.ingredient-table > table tr {
    border-bottom: 1px solid #EFEFEF;
}

.ingredient-table > table th:first-child {
    padding-left: 20px;
}

.ingredient-table > table th:last-child {
}

.ingredient-table > table th {
    font-weight: 400;
    font-size: 12px;
    height: 40px;
}

.ingredient-table > table td {
    font-weight: 500;
    font-size: 14px;
    color: #272722;
}

.ingredient-table > table td:first-child {
    padding-left: 20px;
}

.ingredient-table > table td:last-child {
    /*padding-right: 20px;*/
}

.ingredient-table > table tbody tr {
    background-color: #ffffff;
    height: 60px;
}

.ingredient-table > table tbody tr:hover {
}

.recipe-empty-section {
    background-color: #EFEFEF;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.ingredient-table > table .space {
    height: 4px;
}

.ingredient-table > table input[type='text'] {
    background-color: #EFEFEF;
    border: none;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #313131;
    height: 40px;
    width: 90px;
    box-sizing: border-box;
    text-align: center;
}

.ingredient-table .unit {
    position: relative;
    left: -17px;
    font-size: 10px;
    color: #8C8A81;
}

.ingredient-table .selected .delete {
    visibility: visible;
}

.ingredient-table td :has(.delete) {
    text-align: right;
}

.ingredient-table .delete {
    visibility: hidden;
    padding-right: 10px;
}

.ingredient-table tr:hover .delete {
    visibility: visible;
}

.ingredient-table .delete:hover {
    cursor: pointer;
}

.ingredient-table .text-area-declaration {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 0;
    right: 0;
    border-radius: 8px;
    width: unset;
    height: unset;
}