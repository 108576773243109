
.status {
    font-weight: 400;
    font-size: 12px;
    color: #4D4D4D;
    display: flex;
    align-items: center;
    justify-content: left;
}

.status img {
    margin-right: 5px;
}

.status > div:first-child {
    padding: 0px 6px 0px 4px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status .done {

}

.status .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.status .done .circle {
}


.status .in-progress {
}

.status .in-progress .circle {
}

.status .init {
}

.status .init .circle {
}