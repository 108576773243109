.btn {
    outline: none;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: 8px;
}

/*.btn:hover {*/
/*    cursor: pointer;*/
/*}*/

/**
    Color
 */
.btn-primary {
    background-color: #FC634B;
    color: #FFFFFF;
}

.btn-primary:hover {
    opacity: 0.8;
}

.btn-secondary {
    background-color: #414141;
    border: 1px solid #414141;
}

.btn-secondary:hover {
   opacity: 0.8;
    /*border-color: rgba(49, 49, 49, 0.7);*/
}

.btn-secondary.btn-selected {
    color: #FDAE37;
}

.btn-neutral {
    background-color: #ECE9E1;
    color: #1F1F1F;
}

.btn-submit {
    background-color: #E0DED4;
}

.btn-white {
    background-color: #FFFFFF;
    color: #1F1F1F;
}

.btn-white:hover {
    background-color: #f1f1f1;
}

.btn-red {
    background-color: #DE5242;
    color: #FFFFFF;
}

.btn-red:hover {
    background-color: #ec6557;
}

.btn-green {
    background-color: #40BA22;
    color: #FFFFFF;
}

.btn-green:hover {
    background-color: rgba(64, 186, 34, 0.8);
}

/**
    Size
 */

.btn-small {
    padding: 3px 9px 3px 9px;
    font-size: 11px;
}

.btn-medium {
    padding: 6px 12px 6px 12px;
    font-size: 12px;
}

.btn-large {
    padding: 12px 15px 12px 15px;
    font-size: 13px;
}


/**
    Icon
 */

.btn-start-icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.btn-end-icon {
    margin-left: 10px;
    display: flex;
    align-items: center;
}